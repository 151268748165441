import request from '@/utils/request'


// 查询成功项目列表
export function listSuccess(query) {
  return request({
    url: '/biz/project-success/list',
    method: 'get',
    params: query
  })
}

// 查询成功项目分页
export function pageSuccess(query) {
  return request({
    url: '/biz/project-success/page',
    method: 'get',
    params: query
  })
}

// 查询成功项目详细
export function getSuccess(data) {
  return request({
    url: '/biz/project-success/detail',
    method: 'get',
    params: data
  })
}

// 新增成功项目
export function addSuccess(data) {
  return request({
    url: '/biz/project-success/add',
    method: 'post',
    data: data
  })
}

// 修改成功项目
export function updateSuccess(data) {
  return request({
    url: '/biz/project-success/edit',
    method: 'post',
    data: data
  })
}

// 修改成功项目
export function auditSuccess(data) {
  return request({
    url: '/biz/project-success/audit',
    method: 'post',
    data: data
  })
}

// 删除成功项目
export function delSuccess(data) {
  return request({
    url: '/biz/project-success/delete',
    method: 'post',
    data: data
  })
}
